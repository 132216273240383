var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"first-column"},[_c('ul',{staticClass:"switcher"},[_vm._m(0),_c('li',[(_vm.$i18n.locale !== 'en')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('en')}}},[_vm._v("EN")]):_c('span',{staticStyle:{"cursor":"not-allowed"}},[_vm._v("EN")])]),_c('li',[_vm._v("|")]),_c('li',[(_vm.$i18n.locale !== 'ru')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('ru')}}},[_vm._v("RU")]):_c('span',{staticClass:"disabled"},[_vm._v("RU")])])])]),_c('div',{staticClass:"second-column"},[_c('div',{staticClass:"sections"},[_c('div',{staticClass:"nav"},[_c('ul',[_vm._l((_vm.items),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"active-class":"active","to":{
                  name: 'MovieList',
                  params: {
                    section: item.id,
                    collection: item.collections[0].id
                  }
                }}},[_vm._v(_vm._s(item.title))])],1)}),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Favorites' },"active-class":"active"}},[_vm._v(_vm._s(_vm.$t("navigation.favorites")))])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Press' },"active-class":"active"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.press_footer'))}})],1),_c('li',[_c('a',{attrs:{"href":"/journal/index"}},[_vm._v(_vm._s(_vm.$t("navigation.journal_footer")))])]),_c('li',[_c('router-link',{class:{ disabled: !_vm.isFlight || !_vm.isAuth || !_vm.isService },attrs:{"to":"/services","active-class":"active"}},[_vm._v(_vm._s(_vm.$t("navigation.services_footer")))])],1),_c('li',[_c('a',{staticClass:"not-available disabled",attrs:{"active-class":"active"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.seat_upgrade_footer'))}})]),_c('li',[(_vm.isShopOn)?_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'Shop' },"active-class":"active"}},[_c('span',[_vm._v(_vm._s(_vm.$t("navigation.shop_footer")))])]):_c('span',{staticClass:"not-available disabled"},[_vm._v(" "+_vm._s(_vm.$t("navigation.shop_footer"))+" ")])],1),_c('li',[_c('router-link',{class:{
                  'not-available disabled':
                    !_vm.isFlight || !_vm.isAuth || !_vm.isFoodMenu
                },attrs:{"to":{ name: 'Menu' },"active-class":"active"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.food_menu_footer'))}})],1),_c('li',[(_vm.isFlight && _vm.crewExist)?_c('router-link',{attrs:{"to":{ name: 'Crew' },"active-class":"active"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.crew_footer'))}}):_c('a',{staticClass:"not-available disabled",attrs:{"active-class":"active"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.crew_footer'))}})],1),_c('li',[_c('router-link',{attrs:{"to":"/airline","active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("navigation.airline")))])],1)],2)]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-24 col-m-14 col-s-12 col-links"},[_c('router-link',{staticClass:"btn help",attrs:{"to":"/help"}},[_vm._v(_vm._s(_vm.$t("navigation.help")))]),_c('br'),_c('router-link',{staticClass:"icon license",attrs:{"to":"/license"}},[_vm._v(_vm._s(_vm.$t("languages.license")))]),_c('router-link',{staticClass:"icon privacy",attrs:{"to":"/privacy"}},[_vm._v(_vm._s(_vm.$t("languages.privacy")))])],1),_c('div',{staticClass:"col col-24 col-m-10 col-s-12 col-button"},[_c('span',{staticClass:"button disabled not-available-btn"},[_vm._v(" "+_vm._s(_vm.$t("navigation.feedback"))+" ")])])])])]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('img',{attrs:{"alt":"","src":require("@/assets/i/globe.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v(" © Copyright Premier IFE. Technics, 2024"),_c('br'),_vm._v(" © Content is copyrighted by respective content owners"),_c('br'),_vm._v(" © Entertainment program is copyrighted by AeroGroup llc. ")])])}]

export { render, staticRenderFns }