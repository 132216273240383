var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModal && _vm.authenticate)?_c('div',{staticClass:"modal modal-open"},[_c('div',{staticClass:"modal-help"},[_c('div',{staticClass:"gradient"}),_c('a',{staticClass:"close",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.closeModal($event)}}}),_c('h4',[_vm._v(_vm._s(_vm.$t("services.titlePopup")))]),_c('p',[_vm._v(_vm._s(_vm.$t("services.descriptionPopup")))]),_c('ul',_vm._l((_vm.layoutComp),function(item,index){return _c('li',{key:index,class:{
          active: _vm.orders.find(function (order) { return order.items.find(function (it) { return it.id === item.id; }); }
          ),
          waiting: _vm.waiting.find(function (order) { return +order.id === +item.id; })
        }},[_c('em'),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.setOrderAction(item)}}},[_c('figure',{style:({
              'background-image':
                'url(' + _vm.replaceCoverImage(item.imagePopup) + ')'
            })}),_c('p',{staticClass:"label"},[_vm._v(_vm._s(item.titlePopup))]),_c('p',{staticClass:"cancel",class:{ waiting: _vm.waiting.find(function (order) { return order.id === item.id; }) },on:{"click":function($event){$event.preventDefault();return _vm.cancelOrder({
                id: item.id
              })}}},[_vm._v(" "+_vm._s(_vm.$t("services.cancel"))+" ")])])])}),0),_c('p',{staticClass:"notice"},[_vm._v("Бортпроводник уже выполняет ваш заказ.")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }