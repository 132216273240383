var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"global",class:{ hidden: !_vm.open }},[_c('a',{staticClass:"close",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close', true)}}}),(_vm.$i18n.locale === 'ru')?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/i/logo-red.svg"),"alt":""}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/i/logo-en_red.svg"),"alt":""}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"left-col"},[_c('p',{staticClass:"header"},[_c('router-link',{staticClass:"header",class:{ active: _vm.$route.fullPath === '/' },attrs:{"to":{ name: 'Index' }}},[_vm._v(_vm._s(_vm.$t("navigation.main")))])],1),_c('p',{staticClass:"header"},[_c('router-link',{staticClass:"header",attrs:{"to":{ name: 'Main' },"active-class":"active"}},[_vm._v(_vm._s(_vm.$t("navigation.entertainment")))])],1),_c('ul',[_vm._l((_vm.items),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"active-class":"selected","to":{
                name: 'MovieList',
                params: {
                  section: item.id,
                  collection: item.collections[0].id
                }
              }}},[_vm._v(_vm._s(item.title))])],1)}),_c('li',[_c('router-link',{attrs:{"to":{ name: 'Favorites' },"active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("navigation.favorites")))])],1)],2),_c('p',{staticClass:"header"},[_c('router-link',{attrs:{"to":{ name: 'Press' },"active-class":"active"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.press'))}})],1),_c('p',{staticClass:"header"},[_c('a',{attrs:{"href":"/journal/index"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.journal'))}})]),_c('p',{staticClass:"header"},[_c('router-link',{staticClass:" header",class:{
              selected: _vm.$route.fullPath === '/',
              'not-available disabled': _vm.isBox && !_vm.isService
            },attrs:{"to":{ name: 'Index' }},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.services'))}})],1),_c('ul',[_c('li'),_c('li',[_c('router-link',{class:{
                'not-available disabled': _vm.isBox && (!_vm.isFlight || !_vm.isService)
              },attrs:{"to":{ name: 'Services' },"active-class":"selected"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.services_header_nav'))}})],1),_c('li',[_c('a',{staticClass:"not-available disabled",domProps:{"innerHTML":_vm._s(_vm.$t('navigation.seat_upgrade'))}})]),_c('li',[(_vm.isShopOn)?_c('router-link',{attrs:{"to":{ name: 'Shop' },"active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("navigation.shop_footer")))]):_c('span',{staticClass:"not-available disabled"},[_vm._v(" "+_vm._s(_vm.$t("navigation.shop_footer"))+" ")])],1),_c('li',[_c('router-link',{class:{
                'not-available disabled':
                  _vm.isBox && (!_vm.isFlight || !_vm.isAuth || !_vm.isFoodMenu)
              },attrs:{"to":{ name: 'Menu' }},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.food_menu'))}})],1)])]),_c('div',{staticClass:"right-col"},[_c('p',{staticClass:"header"},[_c('router-link',{staticClass:"not-available header disabled",attrs:{"to":""},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.information'))}})],1),_c('ul',{staticClass:"col-r-p"},[_c('li',[_c('router-link',{class:{
                'not-available disabled': _vm.isBox && (!_vm.isFlight || !_vm.crewExist)
              },attrs:{"to":{ name: 'Crew' },"active-class":"selected"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.crew_header_nav'))}})],1),_c('li',[_c('router-link',{attrs:{"to":"/airline","active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("navigation.airline")))])],1)]),_c('ul',{staticClass:"chooser"},[_c('li',{class:{
              selected: _vm.$i18n.locale === 'ru',
              disabled: _vm.$i18n.locale === 'ru'
            }},[(_vm.$i18n.locale !== 'ru')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('ru')}}},[_c('img',{attrs:{"src":require("@/assets/i/flag-ru.png"),"alt":""}}),_vm._v("Русский")]):_c('span',{staticClass:"disabled"},[_c('img',{attrs:{"src":require("@/assets/i/flag-ru.png"),"alt":""}}),_vm._v("Русский")])]),_c('li',{class:{
              selected: _vm.$i18n.locale === 'en',
              disabled: _vm.$i18n.locale === 'en'
            }},[(_vm.$i18n.locale !== 'en')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('en')}}},[_c('img',{attrs:{"src":require("@/assets/i/flag-en.png"),"alt":""}}),_vm._v("English")]):_c('span',{staticClass:"disabled"},[_c('img',{attrs:{"src":require("@/assets/i/flag-en.png"),"alt":""}}),_vm._v("English")])])]),_c('div',{staticClass:"buttons"},[(_vm.isFlight && !_vm.isAuth)?_c('router-link',{staticClass:"btn auth",attrs:{"to":"/captive"}},[_vm._v(_vm._s(_vm.$t("navigation.authorization_header_nav")))]):_vm._e(),_c('br'),_c('router-link',{staticClass:"btn help",attrs:{"to":"/help"}},[_vm._v(_vm._s(_vm.$t("navigation.help")))]),_c('br'),_c('span',{staticClass:"btn feedback disabled not-available-btn"},[_vm._v(_vm._s(_vm.$t("navigation.feedback")))])],1),_c('div',{staticClass:"links"},[_c('router-link',{staticClass:"privacy",attrs:{"to":"/privacy"}},[_vm._v(_vm._s(_vm.$t("languages.privacy")))]),_c('br'),_c('router-link',{staticClass:"license",attrs:{"to":"/license"}},[_vm._v(_vm._s(_vm.$t("languages.license")))])],1),_c('div',{staticClass:"legals"},[_c('p',[_vm._v(" © Copyright Premier IFE. Technics, 2019 - "+_vm._s(new Date().getUTCFullYear())+" ")]),_c('p',[_vm._v("© Content is a copyright of respective content owners.")]),_c('p',[_vm._v("© Entertainment program is a copyright by AeroGroup llc.")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }