<template>
  <div class="route-bar" v-if="isFlight">
    <!--    <div class="from">-->
    <!--      {{ flightDetails.origin }}-->
    <!--    </div>-->
    <!--    <div class="progress-bar">-->
    <!--      <div class="text" v-if="parseInt(getTime.time) !== 0">-->
    <!--        {{ $t("flight-info.time") }} {{ getTime.time }}-->
    <!--      </div>-->
    <!--      <div class="meter">-->
    <!--        <span :style="getTime.style" />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="to">-->
    <!--      {{ flightDetails.destination }}-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import utils from "@/lib/utils";
import isFlight from "@/mixins/isFlight";

export default {
  name: "RouteBar",
  mixins: [isFlight],
  data: () => {
    return {
      video: null,
      currentTimeLocal: false
    };
  },
  computed: {
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightLoading: state => state.flightLoading,
      flightDetails: state => state.flightDetails,
      flight: state => state.flight,
      serverTime: state => state.serverTime
    }),
    currentTime() {
      if (this.currentTimeLocal) {
        return this.currentTimeLocal;
      } else {
        return false;
      }
    },
    currentFlightTime() {
      if (this.currentTime) {
        if (this.flightDetails.legs[0]) {
          return utils.diffUTCDateTime(
            this.flightDetails.legs[0].depTime,
            `${this.currentTime.getUTCFullYear()}-${
              this.currentTime.getUTCMonth() + 1 <= 9
                ? "0" + (this.currentTime.getUTCMonth() + 1)
                : this.currentTime.getUTCMonth() + 1
            }-${
              this.currentTime.getUTCDate() <= 9
                ? "0" + this.currentTime.getUTCDate()
                : this.currentTime.getUTCDate()
            }T${
              this.currentTime.getUTCHours() <= 9
                ? "0" + this.currentTime.getUTCHours()
                : this.currentTime.getUTCHours()
            }:${
              this.currentTime.getUTCMinutes() <= 9
                ? "0" + this.currentTime.getUTCMinutes()
                : this.currentTime.getUTCMinutes()
            }:${
              this.currentTime.getUTCSeconds() <= 9
                ? "0" + this.currentTime.getUTCSeconds()
                : this.currentTime.getUTCSeconds()
            }`
          );
        }
      }
      return 0;
    },
    getTime() {
      try {
        const durationFlight = utils.diffUTCDateTime(
          this.flightDetails.legs[0].depTime,
          this.flightDetails.legs[0].arrTime
        );

        const percentage = this.currentFlightTime / (durationFlight / 100);
        let endFlight = durationFlight - this.currentFlightTime;
        if (percentage > 100) {
          endFlight = 0;
        }
        return {
          time: utils.buildTimeString(endFlight, true, false),
          style: { width: percentage > 100 ? 100 : percentage + "%" }
        };
      } catch (e) {
        return {
          time: 0,
          style: { width: "0%" }
        };
      }
    }
  },
  methods: {
    ...mapActions("flight", {
      actionServerTime: "serverTime"
    }),
    ...mapMutations("flight", {
      setFlightEntity: "SET_ENTITY"
    })
  },
  async mounted() {
    await this.actionServerTime();
    if (this.serverTime) {
      this.currentTimeLocal = new Date(this.serverTime);
    }
    setInterval(() => {
      if (this.serverTime) {
        const time = new Date(this.serverTime);
        time.setMinutes(time.getMinutes() + 1);
        this.currentTimeLocal = time;
        this.setFlightEntity(["serverTime", time]);
      }
    }, 60000);
  }
};
</script>

<style scoped></style>
