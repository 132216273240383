<template>
  <div class="modal default-modal auth-modal modal-open">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container">
          <a href="" class="close" @click.prevent="closeModal"></a>

          <div class="modal-content">
            <h2>{{ $t("user.authorization") }}</h2>
            <p class="margin-top ta-left">{{ $t("user.authorization_info") }}</p>
            <form method="post" enctype="multipart/form-data">
              <div class="field-row">
                <label for="name">{{ $t("user.first_name") }}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  :class="{ is_invalid: authError }"
                  v-model="user.firstName"
                />
              </div>

              <div class="field-row">
                <label for="sirname">{{ $t("user.last_name") }}</label>
                <input
                  type="text"
                  name="sirname"
                  id="sirname"
                  :class="{ is_invalid: authError }"
                  v-model="user.lastName"
                />
              </div>

              <div class="field-row">
                <label for="seat">{{ $t("user.seat") }}</label>
                <input
                  type="text"
                  name="seat"
                  id="seat"
                  :class="{ is_invalid: authError }"
                  v-model="user.seat"
                />
              </div>
              <div class="field-row" v-if="authError">
                <label style="color: red">{{ $t("user.error") }}</label>
              </div>

              <div class="buttons">
                <input
                  type="submit"
                  name="enter"
                  :value="$t('user.login')"
                  @click.prevent="submit"
                />
              </div>

              <p class="legal" v-html="$t('user.login_policy')"></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import events from "@/mixins/events";

export default {
  name: "Login",
  mixins: [events],
  data: () => {
    return {
      user: {
        firstName: "",
        lastName: "",
        seat: ""
      },
      errors: false
    };
  },
  beforeDestroy() {
    this.setUserEntity(["authError", false]);
  },
  computed: {
    ...mapState("user", {
      authenticate: state => state.authenticate,
      authError: state => state.authError
    })
  },
  methods: {
    ...mapActions("user", {
      loginAction: "loginUser"
    }),
    ...mapMutations("user", {
      setUserEntity: "SET_ENTITY"
    }),
    closeModal() {
      this.setUserEntity(["showModal", false]);
    },
    async submit() {
      await this.loginAction(this.user);
      // this.errors = !this.authenticate;
      if (this.authenticate) {
        this._loginEvent();
      }
    }
  }
};
</script>

<style scoped>
.modal.default-modal.auth-modal.modal-open {
  z-index: 11002;
}
</style>
