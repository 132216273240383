<template>
  <header>
    <ul class="navigation">
      <li class="nav">
        <a href="#" @click.prevent="$emit('openNavigation', true)"
          ><img alt="" src="@/assets/i/nav.svg"
        /></a>
      </li>
      <li class="local-nav">
        <router-link
          style="overflow-y: hidden; overflow-x: hidden"
          v-if="currentSection && !$route.params.section"
          :to="{
            name: 'MovieList',
            params: {
              section: currentSection.id,
              collection: currentSection.collections[0].id
            }
          }"
          ><img src="@/assets/i/arr-left.svg" alt="" /><span
            style="overflow-y: hidden"
            >{{ currentSection.title }}</span
          ></router-link
        >
        <router-link
          v-else-if="$route.params.section"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{ name: 'Main' }"
          ><img src="@/assets/i/arr-left.svg" alt="" /><span
            style="overflow-y: hidden; overflow-x: hidden"
            >{{ $t("navigation.entertainment") }}</span
          ></router-link
        >
        <router-link
          v-else-if="$router.currentRoute.name === 'Press'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{ name: 'Index' }"
          ><img src="@/assets/i/arr-left.svg" alt="" /><span
            style="overflow-y: hidden; overflow-x: hidden"
            >{{ $t("navigation.press_crumbs") }}
          </span></router-link
        >
        <router-link
          v-else-if="$router.currentRoute.name === 'PressNumber'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{ name: 'Press' }"
          ><img src="@/assets/i/arr-left.svg" alt="" /><span
            style="overflow-y: hidden; overflow-x: hidden"
            >{{ $t("navigation.press_crumbs") }}
          </span></router-link
        >
        <router-link
          v-else-if="$router.currentRoute.name === 'PressItem'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{
            name: 'PressNumber',
            params: { number: $route.params.number }
          }"
          ><img src="@/assets/i/arr-left.svg" alt="" /><span
            style="overflow-y: hidden; overflow-x: hidden"
            >{{ $t("navigation.press_crumbs") }}
          </span></router-link
        >
        <router-link
          v-else-if="$router.currentRoute.name === 'ShopItem'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{
            name: 'ShopCategory',
            params: { category: $route.params.category }
          }"
          ><img src="@/assets/i/arr-left.svg" alt=""/><span
            style="overflow-y: hidden; overflow-x: hidden"
            v-html="$t('navigation.shop_crumbs')"
          >
          </span
        ></router-link>
        <router-link
          v-else-if="$router.currentRoute.name === 'ShopCategory'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{
            name: 'Shop'
          }"
          ><img src="@/assets/i/arr-left.svg" alt=""/><span
            style="overflow-y: hidden; overflow-x: hidden"
            v-html="$t('navigation.shop_crumbs')"
          >
          </span
        ></router-link>
        <router-link
          v-else-if="$router.currentRoute.name === 'ShopCard'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{
            name: 'Shop'
          }"
          ><img src="@/assets/i/arr-left.svg" alt=""/><span
            style="overflow-y: hidden; overflow-x: hidden"
            v-html="$t('navigation.shop_crumbs')"
          >
          </span
        ></router-link>
        <router-link
          v-else-if="$router.currentRoute.name !== 'Index'"
          style="overflow-y: hidden; overflow-x: hidden"
          :to="{ name: 'Index' }"
          ><img src="@/assets/i/arr-left.svg" alt="" /><span
            style="overflow-y: hidden; overflow-x: hidden"
            >{{ $t("navigation.home") }}
          </span></router-link
        >
      </li>

      <li class="route">
        <RouteBar></RouteBar>
      </li>
      <li class="basket">
        <router-link to="/shop/card" v-if="isShopOn"
          ><img src="@/assets/i/basket.svg" alt="" /><span
            v-if="shopCard.length"
            >{{ shopCard.length }}</span
          ></router-link
        >
        <a v-else><img src="@/assets/i/basket-close.svg" alt="" /></a>
      </li>
      <li class="bells">
        <a href="#" @click.prevent="showModal"
          ><img v-if="isService" alt="" src="@/assets/i/bells.svg"/><img
            v-else
            alt=""
            src="@/assets/i/no-service-icon.svg"
        /></a>
      </li>
      <li class="language">
        <a @click.prevent="setLocale('ru')" href="" v-if="$i18n.locale === 'en'"
          ><img alt="" src="@/assets/i/flag-en.svg"
        /></a>
        <a
          @click.prevent="setLocale('en')"
          href=""
          v-else-if="$i18n.locale === 'ru'"
          ><img alt="" src="@/assets/i/flag-ru.svg"
        /></a>
      </li>
      <li class="logo">
        <router-link to="/">
          <picture>
            <source srcset="@/assets/i/logo-s.svg" media="(max-width: 767px)" />
            <img
              v-if="$i18n.locale === 'ru'"
              src="@/assets/i/logo.svg"
              width="100%"
              height="auto"
              alt=""
            /><img
              v-else
              src="@/assets/i/logo_en.svg"
              width="100%"
              height="auto"
              alt=""
            /> </picture
        ></router-link>
      </li>
    </ul>
  </header>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RouteBar from "@/components/RouteBar";
import isFlight from "@/mixins/isFlight";

export default {
  name: "HeaderCore",
  components: { RouteBar },
  mixins: [isFlight],
  computed: {
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    ...mapState("content", {
      contentData: state => state.content,
      indexContentLoading: state => state.indexLoading
    }),
    ...mapState("audio", {
      audioContentData: state => state.content
    }),
    ...mapState("shop", {
      shopCard: state => state.card
    }),
    currentSection() {
      if (this.$route.params.section) {
        return this.indexSectionData.find(
          item => item.id === +this.$route.params.section
        );
      } else if (+this.$route.params.id === this.contentData.id) {
        const section = this.contentData.collections.find(it => it.isSection);
        if (section) {
          return this.indexSectionData.find(
            item => item.id === section.collectionId
          );
        }
      } else if (+this.$route.params.id === this.audioContentData.id) {
        const section = this.audioContentData.collections.find(
          it => it.isSection
        );
        if (section) {
          return this.indexSectionData.find(
            item => item.id === section.collectionId
          );
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions("order", {
      showModal: "showModal"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style></style>
